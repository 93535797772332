<template>
  <div class="requests_detail mt-4">
    <v-card v-if="loader" class="pb-4 mb-7">
      <v-container class="pa-5 pb-0">
        <v-row no-gutters align="center">
          <v-col md="auto">
            <p class="font-weight-bold">№ {{ $route.params.id }}</p>
          </v-col>
          <v-col md="auto" class="pl-3">
            <p class="font-weight-bold">
              <span v-if="details.user" v-text="details.user"></span>
              <span v-else>-</span>
            </p>
          </v-col>
          <v-col md="auto" class="pl-3">
            <p class="font-weight-bold">
              {{ details.balance }}
            </p>
          </v-col>

          <v-row class="pb-3 pl-12">
            <v-col v-if="userRole == 'operator' || userRole == 'operator_staff'">
              <div v-if="details.status === 'not confirmed'">
                <v-btn
                  :loading="acceptAllLoading"
                  @click="acceptAll('confirmed')"
                  class="mr-4 mb-3"
                  color="#06B04A"
                  elevation="2"
                  outlined
                  >Подтвердить все</v-btn
                >
                <v-btn
                  :loading="acceptAllLoading"
                  @click="acceptAll('rejected')"
                  color="#FF475D"
                  elevation="2"
                  outlined
                  >Отклонить все</v-btn
                >
              </div>
              <div v-else-if="details.status == 'confirmed' && details.is_my || details.status === 'refund'">
                <button @click="returnRequest" class="request_return">Сделать возврат</button>
              </div>
              <div v-else>{{ details.status | convertStatus }}</div>
            </v-col>
            <v-col v-else>
              <button
                v-if="details.status === 'confirmed'"
                @click="returnRequest"
                class="request_return"
              >
                Запрос на возврат
              </button>
              <div v-else v-text="details.status | convertStatus"></div>
            </v-col>
          </v-row>
          <v-spacer />
          <v-col>
            <v-btn color="primary" @click="resendEmail" :loading="resendEmailLoading">Переотправить</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        v-if="userRole != 'agent_admin' && userRole != 'agent_staff'"
        @click="goInvoice"
        :loading="prihodnikLoading"
        color="green accent-4"
        class="ml-3"
        outlined
        >Инвойс</v-btn
      >
      <!--  
            confirmed - подтверждено - ничего не делать
            not confirmed - не подтверждено - 
            refunded - возвращено
            canceled - отклонено
            refund - запрос на возврат 
      -->
      <div v-if="details.service_type == 'bus'">
        <h4 class="pt-8 pb-0 pl-5 pb-4">Автобус</h4>
        <v-row class="pl-8" align="start">
          <h4>Пожелания туроператора:</h4>
          <p class="ml-2">
            {{
              details.bus_order_requests[0].wishes
                ? details.bus_order_requests[0].wishes
                : "Нет"
            }}
          </p>
        </v-row>
        <v-row class="pl-8 pt-3" align="start">
          <h4>Комментарии:</h4>
          <p class="ml-2">
            {{
              details.bus_order_requests[0].comment
                ? details.bus_order_requests[0].comment
                : "Нет"
            }}
          </p>
        </v-row>
        <v-data-table
          class="mt-3"
          :headers="busesHeadFilter()"
          :items="details.bus_order_requests"
          disable-sort
          disable-filtering
          disable-pagination
          hide-default-footer
          item-key="client_id"
        >
        <!-- item.is_back ? item.hotel_name : item.from_location.name -->
          <template v-slot:[`item.bus`]="{ item }">
            {{ getFormatedDate(item.from_date) + " - " + getFormatedDate(item.to_date) }}
          </template>
          <template v-slot:[`item.from_location`]="{ item }">
            {{ item.route.is_back ? item.hotel : item.from_location }}
          </template>
          <template v-slot:[`item.to_location`]="{ item }">
            {{ item.route.is_back ? item.to_location : `${item.to_location}(${item.hotel})` }}
          </template>
          <template v-slot:[`item.full_name`]="{ item }">
            {{ `${item.first_name || ''} ${item.last_name || ''} ${item.middle_name || ''}` }}</template
          >
          <template v-slot:[`item.type`]="{ item }">
            {{ item.route.bus.bus_type == "sleeping" ? "Спальный" : "Сидячий" }}</template
          >
          <template v-slot:[`item.status`]="{ item }">
            <v-col v-if="userRole == 'operator' || userRole == 'operator_staff'">
              <div v-if="item.status === 'not confirmed'">
                <v-btn
                  :loading="acceptAllLoading"
                  @click="acceptAll('confirmed')"
                  class="mr-4 mb-3"
                  color="#06B04A"
                  elevation="2"
                  outlined
                  >Подтвердить все</v-btn
                >
                <v-btn
                  :loading="acceptAllLoading"
                  @click="acceptAll('rejected')"
                  color="#FF475D"
                  elevation="2"
                  outlined
                  >Отклонить все</v-btn
                >
              </div>
              <div v-else-if="item.status == 'confirmed' && details.is_my || item.status === 'refund'">
                <button @click="returnRequest(item)" class="request_return">Сделать возврат</button>
              </div>
              <div v-else>{{ item.status | convertStatus }}</div>
            </v-col>
            <v-col v-else>
              <button
                v-if="item.status === 'confirmed'"
                @click="returnRequest"
                class="request_return"
              >
                Запрос на возврат
              </button>
              <div v-else>{{ item.status | convertStatus }}</div>
            </v-col>
          </template>
          <template v-slot:[`item.resend`]="{ item }">
            <v-btn
              @click="resendEmail(item)"
              color="primary"
              :loading="resendEmailLoading"
            >
              Переотправить
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <div v-else-if="details.service_type == 'tour'">
        <h4 class="pt-8 pb-0 pl-5 pb-4">Туры</h4>
        <v-data-table
          :headers="toursHeadFilter()"
          :items="details.tour_order_requests"
          disable-sort
          disable-filtering
          disable-pagination
          hide-default-footer
          item-key="client_id"
        >
          <template v-slot:[`item.place_name`]="{ item }">
            {{ item.to_location }}
          </template>
          <template v-slot:[`item.ticket_price`]="{ item }">
            <!-- Взрослый: {{ item.bus.adult_ticket }} <br />
            Детский: {{ item.bus.children_ticket }} -->
            {{ item.total_price }}
          </template>
          <template v-slot:[`item.from_location`]="{ item }">
            {{ item.from_location }}
          </template>
          <template v-slot:[`item.to_date`]="{ item }">
            {{ getFormatedDate(item.from_date) }}
          </template>
          <template v-slot:[`item.to_location`]="{ item }">
            {{ item.to_location }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.bus.type == "Лежачий" ? "Спальный" : "Сидячий" }}
          </template>
          <template v-slot:[`item.cost`]="{ item }">
            {{ item.cost }} тг
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-col v-if="userRole == 'operator' || userRole == 'operator_staff'">
              <div v-if="item.status === 'not confirmed'">
                <v-btn
                  :loading="acceptAllLoading"
                  @click="acceptAll('confirmed')"
                  class="mr-4 mb-3"
                  color="#06B04A"
                  elevation="2"
                  outlined
                  >Подтвердить все</v-btn
                >
                <v-btn
                  :loading="acceptAllLoading"
                  @click="acceptAll('rejected')"
                  color="#FF475D"
                  elevation="2"
                  outlined
                  >Отклонить все</v-btn
                >
              </div>
              <div v-else-if="item.status == 'confirmed' && details.is_my || item.status === 'refund'">
                <button @click="returnRequest(item)" class="request_return">Сделать возврат</button>
              </div>
              <div v-else>{{ item.status | convertStatus }}</div>
            </v-col>
            <v-col v-else>
              <button
                v-if="item.status === 'confirmed'"
                @click="returnRequest"
                class="request_return"
              >
                Запрос на возврат
              </button>
              <div v-else>{{ item.status | convertStatus }}</div>
            </v-col>
          </template>
          <template v-slot:[`item.resend`]="{ item }">
            <v-btn
              @click="resendEmail(item)"
              color="primary"
              :loading="resendEmailLoading"
            >
              Переотправить
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <div v-else-if="details.service_type == 'transfers'">
        <h4 class="pt-8 pb-0 pl-5 pb-4">Трансфер</h4>
        <v-data-table
          :headers="transHeadFilter()"
          :items="details.transfers"
          disable-sort
          disable-filtering
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.date`]="{ item }">
            <div v-if="item.is_rt">
              Туда: {{ getFormatedDateTrans(item.checkout_time) }} <br />
              Обратно: {{ getFormatedDateTrans(item.arrival_time) }}
            </div>
            <div v-else>
              {{ getFormatedDateTrans(item.checkout_time) }}
            </div>
          </template>
          <template v-slot:[`item.taxicheck`]="{ item }">
            <div
              v-if="
                $store.state.auth.currentUser.user.user_role === 'agent_admin'
              "
            >
              <div v-if="item.taxi">
                {{ item.taxi.full_name }}
              </div>
              <div v-else>В ожидании</div>
            </div>
            <div v-else>
              <div v-if="item.taxi">
                {{ item.taxi.full_name }}
              </div>
              <div v-else>
                <v-btn
                  :disabled="item.rejected == true"
                  small
                  color="primary"
                  @click="addTaxi(item)"
                  >Добавить</v-btn
                >
              </div>
            </div>
          </template>
          <template v-slot:[`item.is_rt`]="{ item }">
            {{ item.is_rt ? "Да" : "Нет" }}
          </template>
          <template v-slot:[`item.location`]="{ item }">
            {{ item.transfer.location.name }}
          </template>
          <template v-slot:[`item.direction`]="{ item }">
            {{
              item.transfer.direction.locations.map((el) => el.name).join("-")
            }}
          </template>
          <template v-slot:[`item.accepted`]="{ item }">
            <div
              v-if="
                $store.state.auth.currentUser.user.user_role === 'agent_admin'
              "
            >
              <div v-if="item.accepted">Подтверждено</div>
              <div v-if="!item.accepted && !item.rejected">Не подтверждено</div>
              <div v-else-if="item.rejected">Отклонён</div>
            </div>
            <div v-else>
              <div v-if="item.accepted">Подтверждено</div>
              <div v-else-if="item.rejected">Отклонён</div>
              <div v-else>
                <v-btn
                  :disabled="item.taxi == null"
                  @click="accomAccept(item.id, 'accepted')"
                  class="mr-4"
                  color="#06B04A"
                  elevation="2"
                  outlined
                  >Подтвердить</v-btn
                >
                <v-btn
                  @click="accomAccept(item.id, 'rejected')"
                  color="#FF475D"
                  elevation="2"
                  outlined
                  >Отклонить</v-btn
                >
              </div>
            </div>
          </template>
          <template v-slot:[`item.return`]="{ item }">
            <div v-if="!item.rejected && item.accepted">
              <div v-if="item.returned">Возвращенно</div>
              <div v-else>
                <button
                  v-if="details.is_my"
                  @click.stop="returnRequest(item, 'transfer')"
                  class="request_return"
                >
                  Запрос на возврат
                </button>
                <div v-else>Без действий</div>
              </div>
            </div>
            <div v-else>Невозможен</div>
          </template>
          <template v-slot:[`item.returnMy`]="{ item }">
            <div v-if="!item.rejected">
              <div v-if="details.is_my">
                <button
                  @click="returnRequest(item, 'transfer')"
                  class="request_return"
                >
                  Сделать возврат
                </button>
              </div>
              <div v-else>Без действий</div>
            </div>
            <div v-else>Невозможен</div>
          </template>
          <template v-slot:[`item.resend`]="{ item }">
            <v-btn
              @click="resendEmail(item)"
              color="primary"
              :loading="resendEmailLoading"
            >
              Переотправить
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <div v-else-if="details.service_type == 'railway'">
        <h4 class="pt-8 pb-0 pl-5 pb-4">Ж/Д</h4>
        <v-data-table
          :headers="trainsHeadFilter()"
          :items="details.railway_order_requests"
          disable-sort
          disable-filtering
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ getFormatedDate(item.from_date) + ' - ' + getFormatedDate(item.to_date) }}
          </template>
          <template v-slot:[`item.wagon_number`]="{ item }">
            {{ item.route_railway.railway.train_number }}
          </template>
          <template v-slot:[`item.phone_number`]="{ item }">
            {{ item.railway_order_clients ? item.railway_order_clients[0].phone_number : '-' }}
          </template>
          <template v-slot:[`item.wagon_type`]="{ item }">
            {{ item.route_railway.railway.railway_type }}
          </template>
          <template v-slot:[`item.train_number`]="{ item }">
            {{ item.route_railway.railway.railway_number }}
          </template>
          <template v-slot:[`item.seat_place`]="{ item }">
            {{ item.railway_order_clients ? item.railway_order_clients[0].seat_place.name : '-' }}
          </template>
          <template v-slot:[`item.is_rt`]="{ item }">
            {{ item.is_rt ? "Да" : "Нет" }}
          </template>
          <template v-slot:[`item.time`]="{ item }">
            {{ getFormatedTime(item.from_date) + ' - ' + getFormatedTime(item.to_date) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div v-if="item.status == 'expired'" class="requests_status__false">
              Истек срок
            </div>
            <div v-else-if="item.status == 'canceled' || item.status == 'client_cancel' || item.status == 'company_cancel'"
              class="requests_status__false"
            >
              Отменён
            </div>
            <div v-else-if="userRole === 'agent_admin'">
              <div v-if="item.status == 'confirmed'">Подтверждено</div>
              <div v-else-if="item.status == 'not confirmed'">Не подтверждено</div>
              <div v-else-if="item.status == 'canceled'">Отклонён</div>
            </div>
            <div v-else-if="item.status == 'confirmed'">
                Подтверждено
            </div>
            <div v-else-if="item.status == 'refunded'">Возвращено</div>
            <button
              v-else-if="details.is_my && item.status == 'refund'"
              @click="returnRequest(item, 'accommodation')"
              class="request_return"
            >
              Запрос на возврат
            </button>
            <div v-else>
              <v-btn
                @click="accomAccept(item.id, 'accepted')"
                class="mr-4 mb-3"
                color="#06B04A"
                elevation="2"
                outlined
                >Подтвердить</v-btn
              >
              <v-btn
                @click="accomAccept(item.id, 'rejected')"
                color="#FF475D"
                elevation="2"
                outlined
                >Отклонить</v-btn
              >
            </div>
          </template>
          <template v-slot:[`item.resend`]="{ item }">
            <v-btn
              @click="resendEmail(item)"
              color="primary"
              :loading="resendEmailLoading"
            >
              Переотправить
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <h4 class="pt-8 pb-4 mb-2 ml-5">Проживание</h4>
        <v-data-table
          :headers="accomodationHeadFilter()"
          :items="details.accommodation_order_requests"
          disable-sort
          disable-filtering
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.place`]="{ item }">
            {{ item.place.name }}
          </template>
          <template v-slot:[`item.fullName`]="{ item }">
            <div v-if="item.accommodation_order_clients.length < 1">
              Нет клиентов
            </div>
            <div v-else>
              {{ item.accommodation_order_clients[0].first_name }} <br />
              {{ item.accommodation_order_clients[0].last_name }} <br />
              {{ item.accommodation_order_clients[0].patronymic }}
            </div>
          </template>
          <template v-slot:[`item.phone_number`]="{ item }">
            <div v-if="item.accommodation_order_clients.length < 1">
              Нет номера телефона
            </div>
            <div v-else>
              {{ item.accommodation_order_clients[0] ? item.accommodation_order_clients[0].phone_number : '-' }}
            </div>
          </template>
          <template v-slot:[`item.dates`]="{ item }">
            С: {{ formatAccom(item.check_in) }} <br />
            По: {{ formatAccom(item.check_out) }}
          </template>
          <template v-slot:[`item.people_count`]="{ item }">
            {{ Number(item.adult_count) + Number(item.child_count) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-col v-if="userRole == 'operator' || userRole == 'operator_staff'">
              <div v-if="item.status === 'not confirmed'">
                <v-btn
                  :loading="acceptAllLoading"
                  @click="acceptAll('confirmed')"
                  class="mr-4 mb-3"
                  color="#06B04A"
                  elevation="2"
                  outlined
                  >Подтвердить все</v-btn
                >
                <v-btn
                  :loading="acceptAllLoading"
                  @click="acceptAll('rejected')"
                  color="#FF475D"
                  elevation="2"
                  outlined
                  >Отклонить все</v-btn
                >
              </div>
              <div v-else-if="item.status == 'confirmed' && details.is_my || item.status === 'refund'">
                <button @click="returnRequest(item)" class="request_return">Сделать возврат</button>
              </div>
              <div v-else>{{ item.status | convertStatus }}</div>
            </v-col>
            <v-col v-else>
              <button
                v-if="item.status === 'confirmed'"
                @click="returnRequest"
                class="request_return"
              >
                Запрос на возврат
              </button>
              <div v-else>{{ item.status | convertStatus }}</div>
            </v-col>
          </template>
          <template v-slot:[`item.resend`]="{ item }">
            <v-btn
              @click="resendEmail(item)"
              color="primary"
              :loading="resendEmailLoading"
            >
              Переотправить
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <div v-if="details.accommodation_discount_list.length > 0">
        <h4 class="pt-8 mb-2 ml-5">Скидки</h4>
        <div class="ml-5" v-for="(item) in details.accommodation_discount_list" :key="item.id">
          <p class="mb-2">{{ item.discount_name }} - {{ item.value.toLocaleString('KZ-kz') }} KZT * {{ item.quantity }}</p>
        </div>
      </div>
      <v-row class="pa-3">
        <v-col cols="3">
          <v-text-field
            :value="+details.total_price"
            readonly
            label="К оплате"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :value="details.paid"
            readonly
            label="Оплачено"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :value="details.total_price - details.paid"
            readonly
            label="Осталось оплатить"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :value="details.source"
            readonly
            label="Канал продажи"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pa-3">
        <v-col cols="4">
          <v-text-field
            v-model="details.value"
            label="Скидка"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="details.discount_type"
            :items="discounts"
            label="Тип скидки"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" @click="saveDiscount">Сохранить</v-btn>
        </v-col>
      </v-row>
      <!-- payment -->
      <v-form ref="paymentForm">
        <v-row
          class="mt-5 px-3"
          v-for="(pay, index) in details.payments"
          :key="index"
        >
          <v-col cols="2">
            <v-text-field
              :disabled="pay.id > 0"
              v-model="pay.price"
              @keydown="preventSigns"
              min="1"
              type="number"
              label="Сумма"
              :rules="[(v) => !!v]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              :disabled="pay.id > 0"
              v-model="pay.payment_type"
              label="Тип"
              :items="paymentTypes"
              :rules="[(v) => !!v]"
            >
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :disabled="pay.id > 0"
              v-model="pay.comment"
              label="Комментарии"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pt-5">
            <v-btn
                v-if="!pay.id"
              @click="executePayment(pay)"
              color="primary"
              :loading="executePaymentLoading"
            >
              Сохранить
            </v-btn>
            <v-btn
                v-if="pay.id && pay.payment_type != 'refund'"
                @click="goPrihodnik(pay.id)"
                :loading="prihodnikLoading"
                color="primary"
                class="ml-3"
                outlined
                >Приходник</v-btn
            >
            <v-btn
              v-if="!pay.id"
              @click="deletePayment(pay, index)"
              color="primary"
              outlined
              class="ml-2"
              :loading="deletePaymentLoading"
            >
              Удалить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        v-if="details.balance"
        @click="addPayment"
        class="ml-3 mt-5"
        color="primary"
      >
        + Добавить платёж
      </v-btn>
    </v-card>
    <v-dialog v-model="dialog" width="687">
      <v-card class="pa-5" style="position: relative">
        <img
          style="
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;
            width: 30px;
          "
          src="@/assets/images/navigation/exit.svg"
          @click="dialog = false"
        />
        <v-row>
          <v-col cols="6" md="6"
            ><h2 style="color: #52b1ba">Информация</h2>
          </v-col>
          <v-col cols="6" md="6"> </v-col>
          <v-col cols="6" md="6">
            <v-autocomplete
              v-model="driverName"
              :items="arrdriverName"
              :loading="isLoading"
              :search-input.sync="search"
              label="Выберите водителя"
              clearable
              style="height: 50px"
              @change="getDriverDetails"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="carDetails.car_model"
              readonly
              label="Марка авто"
              :loading="loading"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="carDetails.color"
              readonly
              label="Цвет"
              :loading="loading"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="carDetails.number"
              readonly
              label="Гос. номер"
              :loading="loading"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              v-model="carDetails.phone_number"
              readonly
              label="Телефон"
              :loading="loading"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="bindDriver"
            :loading="taxiBtnLoading"
            color="primary"
            width="160"
            height="44"
            >Выбрать</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isreturnRequest" width="387">
      <v-card class="request_pop">
        <h2>Уверены ли вы, что хотите сделать возврат?</h2>
        <v-btn
          @click="executeReturn"
          color="#52b1ba"
          outlined
          :loading="executeReturnLoading"
        >
          Запрос на возврат
        </v-btn>
        <button
          @click="isreturnRequest = false"
          style="
            color: #ff8413;
            filter: drop-shadow(0px 20px 20px rgba(82, 177, 186, 0.15));
            border: 1px solid #ff8413;
          "
        >
          Отмена
        </button>
      </v-card>
    </v-dialog>
    <v-dialog v-model="taxiName" width="452">
      <v-card class="taxiName_pop">
        <img
          style="width: 30px"
          src="@/assets/images/navigation/exit.svg"
          @click="taxiName = false"
        />
        <h1>Трансфер</h1>
        <p>Трансфер</p>
        <v-autocomplete
          v-model="driverName"
          :items="arrdriverName"
          :loading="isLoading"
          :search-input.sync="search"
          label="Выберите водителя"
          solo
          clearable
        >
        </v-autocomplete>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogOper" width="400">
      <v-card class="popup_modal">
        <img
          style="width: 30px"
          @click="dialogOper = false"
          src="@/assets/images/navigation/exit.svg"
        />
        <p class="title_return">Возврат</p>
        <label for="balance">Сумма удержания</label>
        <div class="input">
          <input
            v-model="balance"
            @input="calculatebalance"
            type="number"
            id="balance"
            placeholder="Введите сумму"
            :min="0"
            @keydown="preventSigns"
          />
          <span>KZT</span>
        </div>
        <label for="balance_ret">Сумма к возврату</label>
        <div class="input">
          <input
            v-model="returnbalance"
            readonly
            type="number"
            id="balance_ret"
            style="border: 1px solid #52b1ba"
          />
          <span>KZT</span>
        </div>
        <button @click="submitHandler">Подтвердить возврат</button>
        <button @click="dialogOper = false">Отмена</button>
      </v-card>
    </v-dialog>
    <v-overlay :value="!loader">
      <v-progress-circular
        :size="50"
        color="#52B1BA"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { orderRequestService } from "@/services/orderRequests.service.js";
import { paymentsService } from "@/services/payment.service.js";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import axios from "axios";
export default {
  data: () => ({
    executePaymentLoading: false,
    deletePaymentLoading: false,
    paymentTypes: [
      {
        text: "Платёж",
        value: "payment",
      },
      {
        text: "Возврат",
        value: "refund",
      },
    ],
    discounts: [
      {
        text: 'Процент',
        value: 'percent'
      },
      {
        text: 'Фиксированная',
        value: 'fixed'
      },
    ],
    executeReturnLoading: false,
    taxiBtnLoading: false,
    returnbalance: 0,
    balance: null,
    dialogOper: false,
    rowDetail: null,
    loading: false,
    search: null,
    isLoading: false,
    driverName: null,
    taxiName: false,
    isreturnRequest: false,
    returnItem: null,
    dialog: false,
    carDetails: {},
    loader: false,
    arrdriverName: [],
    acceptAllLoading: false,
    resendEmailLoading: false,
    accomodationHead: [
      { text: "№", align: "start", value: "id" },
      { text: "ФИО", value: "fullName" },
      { text: "Курорт", value: "place" },
      { text: "Отель", value: "hotel" },
      { text: "Кол. ночей", value: "nights" },
      { text: "Проживание", value: "dates" },
      { text: "Тип номера", value: "room_type" },
      { text: "Итого", value: "price" },
      { text: "Доп. место", value: "people_count" },
      { text: "Статус", value: "accepted" },
      { text: "Возврат", value: "return" },
    ],
    details: {},
    prihodnikLoading: false,
  }),
  watch: {
    dialogOper(){
      if(!this.dialogOper) this.returnItem = null
    },
    $route(to) {
      if (to.name == "alldetailsagent" || to.name == "alldetailsoper") {
        this.getDetails();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser", "userRole"]),
  },
  filters: {
    convertStatus(value){
      let statuses = {
        confirmed: "Подтверждено",
        "not confirmed": "Не подтверждено",
        refunded: "Возвращено",
        canceled: "Отклонено",
        refund: "Запрос на возврат",
      }
      return statuses[value]
    }
  },
  created() {
    this.getDetails();
  },
  methods: {
    ...mapActions("base", ["showNotification"]),
    async saveDiscount(){
      let body = {
        order_request: this.$route.params.id,
        value: this.details.value,
        discount_type: this.details.discount_type 
      }
      try{
        this.$loading(true)
        await orderRequestService.changeDiscount(body)
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Сохранилось!",
          color: "success",
        });
        this.getDetails()
      }
      finally {
        this.$loading(false)
      }
    },
    goInvoice() {
      console.log(this.details);
    },
    async goPrihodnik(id) {
      if (this.details.prihodnik) {
        window.open(this.details.prihodnik, "_blank");
      } else {
        let res = await paymentsService.getPrihodnik(id)
        console.log(res);
        window.open(res.cash_receipt_order, "_blank");
      }
    },
    addPayment() {
      this.details.payments.push({});
    },
    async deletePayment(item, idx) {
      try{
        this.$loading(true)
        this.deletePaymentLoading = true;
        if (item.id) {
          await paymentsService.deletePayment(item.id);
          this.details.payments.splice(idx, 1);
          this.deletePaymentLoading = false;
          this.getDetails();
        } else {
          this.details.payments.splice(idx, 1);
          this.deletePaymentLoading = false;
        }
      }
      finally {
        this.deletePaymentLoading = false;
        this.$loading(false)
      }
    },
    async executePayment(item) {
      if (!this.$refs.paymentForm.validate()) return;
      this.executePaymentLoading = true;
      let body = {
        price: item.price.toString(),
        payment_type: item.payment_type,
        order_request: this.$route.params.id,
      };
      try{
        this.$loading(true)
        await paymentsService.createPayment(body);
        this.getDetails();
      }
      finally{
        this.$loading(false)
        this.executePaymentLoading = false;
      }
    },
    async acceptAll(action) {
      this.acceptAllLoading = true;
      let body = { ...this.details, status: action };
      try{
        this.$loading(true)
        await orderRequestService.setOrderRequest(body, this.$route.params.id);
        this.getDetails();
      }
      finally{
        this.$loading(false)
        this.acceptAllLoading = false;
      }
    },
    async resendEmail() {
      if (!this.$route.params.id) return;
      let obj = {
        bus: 'bus',
        accommodation: "accommodation",
        tour: 'tour'
      }
      this.resendEmailLoading = true;
      try{
        this.$loading(true)
        await orderRequestService.resend({ request_id: this.$route.params.id }, obj[this.details.service_type])
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Успешно отправлено!",
          color: "success",
        });
        this.getDetails();
      }
      finally{
        this.$loading(false)
        this.resendEmailLoading = false;
      }
    },
    async submitHandler() {
      this.loading = true;
      let obj = {
        accommodation: {
          path: '/order-requests/accommodation/refund/', // order_ids , refund_price
          order:  'accommodation_order_requests',
          method: 'post'
        },
        railway: {
          path: `/railway-orders/${this.$route.params.id}/refund/`, // order_clients , refund_price
          order: 'railway_order_requests',
          method: 'patch'
        },
        bus: {
          path: `/order-requests/bus/refund/`, // order_ids , refund_price 
          order: 'bus_order_requests',
          method: 'post'
        },
        tour: {
          path: `/order-requests/tour/refund/`, // order_ids , refund_price
          order: 'tour_order_requests',
          method: 'post'
        },
      }
      let body = {
        order_clients: [],
        order_ids: [],
        refund_price: +this.balance
      }
      if(this.returnItem) {
        body.order_ids = [ this.returnItem.client_id || this.returnItem.id ]
        body.order_clients = [ this.returnItem.id ]
      } 
      else {
        this.details[obj[this.details.service_type].order].forEach((e) => {
          let id = this.details.service_type == 'tour' || this.details.service_type == 'bus' ? e.client_id : e.id
          body.order_clients.push(id)
          body.order_ids.push(id)
        })
      }
      body.order_clients = body.order_clients.join(',')
      let path = obj[this.details.service_type].path
      let method = obj[this.details.service_type].method
      try{
        this.$loading(true)
        let res = (await axios[method](path, body)).data
        if(res.id) this.$router.push(`/requests/all/${res.id}/`)
        console.log(res);
        this.loading = false;
        this.dialogOper = false;
        this.getDetails();
      }
      finally {
        this.$loading(false)
        this.loading = false;
        this.dialogOper = false;
      }
    },
    preventSigns(e) {
      if (e.key == "-" || e.key == "+") {
        e.preventDefault();
      }
    },
    calculatebalance() {
      this.returnbalance = this.returnItem && this.returnItem.calculated_by ? this.returnItem.calculated_by.price - this.balance : this.details.total_price - this.balance;
    },
    formatAccom(date) {
      return this.$moment(Number(date) * 1000).format("DD MMM HH:mm");
    },
    bindDriver() {
      this.taxiBtnLoading = true;
      this.loader = false;
      axios
        .patch(`transfers/orders/taxi/${this.rowDetail.id}/`, {
          taxi: this.carDetails.id,
        })
        .then(() => {
          this.dialog = false;
          this.getDetails();
          this.loader = true;
          this.taxiBtnLoading = false;
        });
    },
    getDriverDetails() {
      this.loading = true;
      axios.get(`transfers/taxi/${this.driverName}/`).then((resp) => {
        this.carDetails = resp.data;
        this.loading = false;
      });
    },
    addTaxi(item) {
      this.rowDetail = item;
      this.dialog = true;
      this.isLoading = true;
      axios.get("transfers/taxi/").then((resp) => {
        resp.data.results.map((el) => {
          let tmp = {
            text: el.full_name,
            value: el.id,
          };
          this.arrdriverName.push(tmp);
        });
        this.isLoading = false;
      });
    },
    executeReturn() {
      this.executeReturnLoading = true;
      axios
        .patch(`request/${this.$route.params.id}/`, {
          has_return: true,
        })
        .then(() => {
          this.executeReturnLoading = false;
          this.isreturnRequest = false;
          this.getDetails();
        });
    },
    returnRequest(item) {
      this.returnItem = item.id ? item : null;
      if (this.userRole == "operator" || this.userRole == "operator_staff") {
        this.dialogOper = true;
        this.returnbalance = this.details.balance;
      }
      else{
        this.isreturnRequest = true
      }
    },
    async accomAccept(id, value) {
        let str = value == 'accepted' ? 'confirm' : 'cancel'
        let obj = {
            accommodation: { 
                path: `/order-requests/accommodation/${str}/`, // order_ids
                method: 'post',
                order:  'accommodation_order_requests',
            },
            railway: { 
                path: `/railway-orders/${id}/${str}/`, // order_clients
                method: 'patch',
                order: 'railway_order_requests',
            },
            bus: { 
                path: `/bus-orders/${id}/${str}/`, // order_clients
                method: 'patch',
                order: 'bus_order_requests',
            },
            tour: { 
                path: `/tour-orders/${id}/${str}/`, // order_clients
                method: 'patch',
                order: 'tour_order_requests',
            },
        }
        let path = obj[this.details.service_type].path
        let method = obj[this.details.service_type].method
        let list = obj[this.details.service_type].order
        let body = {
            order_ids: [id],
            order_clients: [id]
        }
        body.order_clients = body.order_clients.join(',')
        try{
          this.$loading(true)
          let res = (await axios[method](path, body)).data
          if(res.id) this.$router.push(`/requests/all/${res.id}/`)
          this.getDetails();
        }
        finally {
          this.$loading(false)
        }
    },
    getFormatedDateTrans(date) {
      return moment(Number(date) * 1000).format("DD MMMM HH:mm");
    },
    getFormatedDate(date) {
      return moment(Number(date) * 1000).format("DD MMMM");
    },
    getFormatedTime(date) {
      return moment(Number(date) * 1000).format("HH:mm");
    },
    async getDetails() {
      if (!this.$route.params.id) return;
      this.loader = false;
      let res
      try{
        res = await orderRequestService.getOrderRequest(
          this.$route.params.id
        );
      }
      catch(e){
        console.log(e.response);
        if(e.response.status == 404){
          this.$store.commit("ui/SET_NOTIFICATION", {
            show: true,
            message: "Такой страницы не существует! Сейчас вы перенаправитесь на другую страницу!",
            color: "error",
          });
          setTimeout(() => {
            this.$router.push('/requests/all')
          }, 5000);
        }
      }
      this.loader = true;
      let obj = {
        accommodation: {
          list: 'accommodation_order_requests',
          client: 'accommodation_order_clients'
        },
        railway: {
          list: 'railway_order_requests',
          client: 'railway_order_clients'
        },
        bus: {
          list: 'bus_order_requests',

        },
        tour: {
          list: 'tour_order_requests',
          client: 'tour_order_clients'
        },
      }
      if(res.service_type != 'bus'){
        res[obj[res.service_type].list].forEach(e => {
          e.full_name = ''
          let clients = obj[res.service_type].client
          if(e[clients][0]) e.full_name = `${e[clients][0].first_name || ''} ${e[clients][0].last_name || ''} ${e[clients][0].middle_name || ''}`;
          
        })
      }
      this.details = res;
      this.$store.commit('lists/SET_TRIGGER_REQUEST')
    },
    transHeadFilter() {
      if (this.userRole) {
        if (this.userRole == "operator" || this.userRole == "operator_staff") {
          return [
            { text: "№", align: "start", value: "id" },
            { text: "ФИО", value: "full_name" },
            { text: "Номер телефона", value: "phone_number" },
            { text: "Дата", value: "date" },
            { text: "В обе стороны", value: "is_rt" },
            { text: "Отель", value: "hotel" },
            { text: "Маршрут", value: "direction" },
            { text: "Курорт", value: "location" },
            { text: "Таксист", value: "taxicheck", cellClass: "taxi_name" },
            { text: "Цена", value: "balance" },
            { text: "Статус", value: "status" },
          ];
        } else {
          return [
            { text: "№", align: "start", value: "id" },
            { text: "ФИО", value: "full_name" },
            { text: "Номер телефона", value: "phone_number" },
            { text: "Дата", value: "date" },
            { text: "В обе стороны", value: "is_rt" },
            { text: "Отель", value: "hotel" },
            { text: "Маршрут", value: "direction" },
            { text: "Курорт", value: "location" },
            { text: "Таксист", value: "taxicheck", cellClass: "taxi_name" },
            { text: "Цена", value: "balance" },
            { text: "Статус", value: "status" },
          ];
        }
      }
    },
    busesHeadFilter() {
      if (this.userRole) {
        if (this.userRole == "operator" || this.userRole == "operator_staff") {
          return [
            { text: "№", align: "start", value: "client_id" },
            { text: "ФИО", value: "full_name" },
            { text: "Номер телефона", value: "phone_number" },
            { text: "Дата", value: "bus" },
            { text: "Откуда", value: "from_location" },
            { text: "Куда", value: "to_location" },
            { text: "Автобус", value: "type" },
            { text: "Отель", value: "hotel" },
            { text: "Место", value: "seat_place" },
            { text: "Цена", value: "total_price" },
            { text: "Статус", value: "status" },
          ];
        } else {
          return [
            { text: "№", align: "start", value: "client_id" },
            { text: "ФИО", value: "full_name" },
            { text: "Номер телефона", value: "phone_number" },
            { text: "Дата", value: "bus" },
            { text: "Откуда", value: "from_location" },
            { text: "Куда", value: "to_location" },
            { text: "Автобус", value: "type" },
            { text: "Отель", value: "hotel" },
            { text: "Место", value: "seat_place" },
            { text: "Цена", value: "total_price" },
            { text: "Статус", value: "status" },
          ];
        }
      }
    },
    trainsHeadFilter() {
      if (this.userRole) {
        if (this.userRole == "operator" || this.userRole == "operator_staff") {
          return [
            { text: "№", align: "start", value: "id" },
            { text: "ФИО", value: "full_name" },
            { text: "Номер телефона", value: "phone_number" },
            { text: "Туроператор", value: "tour_operator" },
            { text: "Дата", value: "date" },
            { text: "Время", value: "time" },
            { text: "Откуда", value: "from_location" },
            { text: "Куда", value: "to_location" },
            { text: "Поезд", value: "train_number" },
            { text: "Вагон", value: "wagon_number" },
            { text: "Тип вагона", value: "wagon_type" },
            { text: "Место", value: "seat_place" },
            { text: "Цена", value: "price" },
            { text: "Статус", value: "status" },
          ];
        } else {
          return [
            { text: "№", align: "start", value: "id" },
            { text: "ФИО", value: "full_name" },
            { text: "Номер телефона", value: "phone_number" },
            { text: "Туроператор", value: "tour_operator" },
            { text: "Дата", value: "date" },
            { text: "Время", value: "time" },
            { text: "Откуда", value: "from_location" },
            { text: "Куда", value: "to_location" },
            { text: "Поезд", value: "train_number" },
            { text: "Вагон", value: "wagon_number" },
            { text: "Тип вагона", value: "wagon_type" },
            { text: "Место", value: "seat_place" },
            { text: "Цена", value: "price" },
            { text: "Статус", value: "status" },
          ];
        }
      }
    },
    toursHeadFilter() {
      if (this.userRole) {
        // if (this.userRole == "operator" || this.userRole == "operator_staff") {
          return [
            { text: "№", align: "start", value: "client_id" },
            { text: "ФИО", value: "full_name" },
            { text: "Номер телефона", value: "phone_number" },
            { text: "Курорт", value: "place_name" },
            { text: "Цена", value: "ticket_price" },
            { text: "Место", value: "seat_place" },
            // { text: "Стоимость", value: "cost" },
            { text: "Отправление", value: "to_date" },
            { text: "Откуда", value: "from_location" },
            { text: "Куда", value: "to_location" },
            { text: "Статус", value: "status" },
            { text: "Комментарий", value: "comment" },
          ];
        // } else {
        //   return [
        //     { text: "№", align: "start", value: "id" },
        //     { text: "ФИО", value: "full_name" },
        //     { text: "Номер телефона", value: "phone_number" },
        //     { text: "Курорт", value: "place_name" },
        //     { text: "Цена", value: "ticket_price" },
        //     { text: "Место", value: "seatPlaces" },
        //     { text: "Стоимость", value: "cost" },
        //     { text: "Отправление", value: "checkout_time" },
        //     { text: "Откуда", value: "from_location" },
        //     { text: "Куда", value: "to_location" },
        //     { text: "Статус", value: "status" },
        //   ];
        // }
      }
    },
    accomodationHeadFilter() {
      if (this.userRole) {
        return [
          { text: "№", align: "start", value: "id" },
          { text: "ФИО", value: "full_name" },
          { text: "Номер телефона", value: "phone_number" },
          { text: "Курорт", value: "location" },
          { text: "Отель", value: "hotel" },
          { text: "Кол. ночей", value: "calculated_by.days" },
          { text: "Проживание", value: "dates" },
          { text: "Тип номера", value: "category_name" },
          { text: "Итого", value: "total_price" },
          { text: "Доп. место", value: "people_count" },
          // { text: "Скидка", value: "discount" },
          { text: 'Статус', value: 'status' },
        //   { text: 'Почта', value: 'resend' }
          // { text: "Стоимость в сутки", value: "calculated_by.price" },
        ];
        // if (this.userRole == "operator" || this.userRole == "operator_staff") {
        //   return [
        //     { text: "№", align: "start", value: "id" },
        //     { text: "ФИО", value: "fullName" },
        //     { text: "Номер телефона", value: "phone_number" },
        //     { text: "Курорт", value: "place" },
        //     { text: "Отель", value: "hotel" },
        //     { text: "Кол. ночей", value: "nights" },
        //     { text: "Проживание", value: "dates" },
        //     { text: "Тип номера", value: "room_type" },
        //     { text: "Итого", value: "price" },
        //     { text: "Доп. место", value: "people_count" },
        //     // { text: "Статус", value: "accepted" },
        //   ];
        // } else {
        //   return [
        //     { text: "№", align: "start", value: "id" },
        //     { text: "ФИО", value: "fullName" },
        //     { text: "Номер телефона", value: "phone_number" },
        //     { text: "Курорт", value: "place" },
        //     { text: "Отель", value: "hotel" },
        //     { text: "Кол. ночей", value: "nights" },
        //     { text: "Проживание", value: "dates" },
        //     { text: "Тип номера", value: "room_type" },
        //     { text: "Итого", value: "price" },
        //     { text: "Доп. место", value: "people_count" },
        //     // { text: "Статус", value: "accepted" },
        //   ];
        // }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.taxi_name {
  color: #ff8413 !important;
}
.info_pop {
  position: relative;
  height: 427px;
  padding-top: 40px;
  padding-left: 70px;
  border-radius: 5px;
  img {
    position: absolute;
    top: 20px;
    right: 32px;
    cursor: pointer;
  }
  h1 {
    font-size: 26px;
    line-height: 30px;
    color: #52b1ba;
    margin-bottom: 30px;
  }
  .grid_items {
    display: grid;
    grid-template-columns: repeat(2, 260px);
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    p {
      font-size: 16px;
      line-height: 19px;
      color: #021011;
      margin: 0;
    }
    .single_box {
      width: 260px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 5px;
      margin-top: 14px;
      p {
        margin-bottom: 0 !important;
      }
    }
  }
}
.add_service {
  width: 260px;
  height: 50px;
  background: #52b1ba;
  border: 1px solid #52b1ba;
  box-sizing: border-box;
  box-shadow: 0px 20px 20px rgba(82, 177, 186, 0.15);
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.service_pop {
  height: 399px;
  background: #ffffff;
  border-radius: 5px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  h1 {
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    color: #ff8413;
    margin-bottom: 36px;
  }
  .single_box {
    height: 120px;
    width: 193px;
    background: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
    border-radius: 5px;
    cursor: pointer;
  }
  .top_row {
    display: grid;
    grid-template-columns: repeat(3, 193px);
    grid-column-gap: 18px;
  }
  .bot_row {
    display: grid;
    grid-column-gap: 18px;
    margin-top: 18px;
    grid-template-columns: repeat(2, 193px);
  }
}
.request_return {
  width: 153px;
  height: 34px;
  border: 1px solid #ff8413;
  box-sizing: border-box;
  filter: drop-shadow(0px 20px 20px rgba(82, 177, 186, 0.15));
  border-radius: 5px;
  outline: none;
  font-size: 15px;
  line-height: 19px;
  color: #ff8413;
}
.request_pop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 41px;
  border-radius: 5px;
  height: 271px;
  h2 {
    width: 251px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
  }
  button {
    width: 200px;
    height: 40px;
    outline: none;
    font-size: 16px;
    line-height: 19px;
    border-radius: 5px;
    &:first-of-type {
      margin-bottom: 12px;
    }
  }
}
.taxiName_pop {
  position: relative;
  height: 222px;
  padding-top: 40px;
  padding-left: 70px;
  padding-right: 70px;
  img {
    position: absolute;
    top: 20px;
    right: 32px;
    cursor: pointer;
  }
  h1 {
    font-size: 26px;
    line-height: 30px;
    color: #52b1ba;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #021011;
    margin-bottom: 14px;
  }
}
.popup_modal {
  // height: 500px;
  background: #ffffff;
  border-radius: 5px;
  position: relative;
  padding-top: 40px;
  padding-left: 70px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  img {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 32px;
  }
  p {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #52b1ba;
    margin-bottom: 30px;
  }
  label {
    // font-family: "Gilroy";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #021011;
    margin-bottom: 20px;
  }
  .input {
    position: relative;
    input {
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 5px;
      padding-left: 20px;
      transition: 0.2s;
      outline: none;
      width: 260px;
      height: 50px;
      // font-family: "Gilroy";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #52b1ba;
      &:first-of-type {
        margin-bottom: 26px;
      }
      &:focus {
        border: 1px solid #52b1ba;
      }
    }
    span {
      // font-family: "Gilroy";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #52b1ba;
      position: absolute;
      top: 17px;
      right: 23px;
    }
  }
  button {
    width: 260px;
    height: 50px;
    outline: none;
    background: #52b1ba;
    border: 1px solid #52b1ba;
    box-sizing: border-box;
    box-shadow: 0px 20px 20px rgba(82, 177, 186, 0.15);
    border-radius: 5px;
    margin-top: 26px;
    font-size: 16px;
    // font-family: "Gilroy";
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    color: #ffffff;
    &:last-of-type {
      background: #fff;
      color: #52b1ba;
    }
  }
}
::placeholder {
  // font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #e9e9e9;
}
</style>
