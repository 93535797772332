import axios from "axios";
const url = '/payments/'

export const paymentsService = {
    async getPayments(params) {
        return (await axios.get(`${url}`, { params })).data
    },
    async createPayment(body) {
        return (await axios.post(`${url}`, body)).data
    },
    async getPayment(id) {
        return (await axios.get(`${url}${id}/`)).data
    },
    async setPayment(body, id) {
        return (await axios.patch(`${url}${id}/`, body)).data
    },
    async deletePayment(id) {
        return (await axios.delete(`${url}${id}/`)).data
    },
    // скачать приходник
    async getPrihodnik(id) {
        return (await axios.get(`${url}cash-receipt/${id}/`)).data
    }
}

